<template>
    <v-divider class="mb-6 mb-sm-8"></v-divider>
</template>

<script>

export default {
	name: 'divider-view',

	components: {
	},

	props: {

	},

	data: () => ({
	}),

	mounted() {

	},

	created() {

	},

	computed: {

	},

	watch: {},

	methods: {
	}
};
</script>
