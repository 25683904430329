<template>
	<ValidationObserver v-slot="{ valid, invalid, validated, passes, validate, handleSubmit, errors }" ref="observer">
		<div class="pa-5 pa-sm-10 grow d-flex flex-column flex-nowrap" v-if="AppStore.started_app" id="questions-page">
			<v-container class="grow d-flex flex-column flex-nowrap" fluid>
				<v-row align="center" justify="center">
					<v-card elevation="0" v-show="!AppStore.toggleCGU" class="mx-auto py-5 px-2 px-sm-10 ma-5"
					        rounded="xl">
						<v-col cols="12" class="text-h2 pt-5 pb-7 text-center my-auto px-0"
						       v-if="AppStore.data.configuration.display_question_title">
        						<span v-if="Helpers.isNegativeWithComment()"
						              v-html="AppStore.data.configuration[AppStore.locale].title_3_negative_with_comment"></span>
							<span v-if="Helpers.isNegativeWithoutComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_negative_without_comment"></span>
							<span v-if="Helpers.isPositiveWithComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_positive_with_comment"></span>
							<span v-if="Helpers.isPositiveWithoutComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_positive_without_comment"></span>
						</v-col>

						<v-col cols="12 grow" class="pb-0" v-if="AppStore.context_questions.length"
						       id="context-questions">
							<div v-for="(question, questionIndex2) in AppStore.context_questions"
							     :key="'100'+questionIndex2">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'slider'"
								             :rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
								             v-model="AppStore.context_questions[questionIndex2]"
								             :questionIndex="questionIndex2" :lang="AppStore.locale"></slider-view>
								<text-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								           v-if="question.format === 'text'"
								           :rules="question.is_required ? 'required' : ''"
								           v-model="AppStore.context_questions[questionIndex2]"
								           :questionIndex="questionIndex2" :lang="AppStore.locale"></text-view>
								<textarea-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'textarea'"
								               :rules="question.is_required ? 'required' : ''"
								               v-model="AppStore.context_questions[questionIndex2]"
								               :questionIndex="questionIndex2"
								               :lang="AppStore.locale"></textarea-view>
								<select-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'select'"
								             :rules="question.is_required ? 'required' : ''"
								             v-model="AppStore.context_questions[questionIndex2]"
								             :questionIndex="questionIndex2" :lang="AppStore.locale"></select-view>
								<checkbox-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'checkbox'"
								               :rules="question.is_required ? 'required' : ''"
								               :color="AppStore.data.general.primary_color"
								               v-model="AppStore.context_questions[questionIndex2]"
								               :questionIndex="questionIndex2"
								               :lang="AppStore.locale"></checkbox-view>
								<radio-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								            v-if="question.format === 'radio'"
								            :rules="question.is_required ? 'required' : ''"
								            :color="AppStore.data.general.primary_color"
								            v-model="AppStore.context_questions[questionIndex2]"
								            :questionIndex="questionIndex2" :lang="AppStore.locale"></radio-view>
								<switch-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'switch'"
								             :rules="question.is_required ? 'required' : ''"
								             :color="AppStore.data.general.primary_color"
								             v-model="AppStore.context_questions[questionIndex2]"
								             :questionIndex="questionIndex2" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>
						<v-col cols="12" class="pt-0" v-if="AppStore.profil_questions.length" id="profil-questions">
							<div v-for="(question, questionIndex) in AppStore.profil_questions" :key="questionIndex">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'slider'"
								             :rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
								             v-model="AppStore.profil_questions[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>
								<text-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								           v-if="question.format === 'text'"
								           :rules="question.is_required ? 'required' : ''"
								           v-model="AppStore.profil_questions[questionIndex]"
								           :questionIndex="questionIndex"
								           :lang="AppStore.locale"></text-view>
								<textarea-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'textarea'"
								               :rules="question.is_required ? 'required' : ''"
								               v-model="AppStore.profil_questions[questionIndex]"
								               :questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>
								<select-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'select'"
								             :rules="question.is_required ? 'required' : ''"
								             v-model="AppStore.profil_questions[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></select-view>
								<checkbox-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'checkbox'"
								               :rules="question.is_required ? 'required' : ''"
								               :color="AppStore.data.general.primary_color"
								               v-model="AppStore.profil_questions[questionIndex]"
								               :questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>
								<radio-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								            v-if="question.format === 'radio'"
								            :rules="question.is_required ? 'required' : ''"
								            :color="AppStore.data.general.primary_color"
								            v-model="AppStore.profil_questions[questionIndex]"
								            :questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>
								<switch-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'switch'"
								             :rules="question.is_required ? 'required' : ''"
								             :color="AppStore.data.general.primary_color"
								             v-model="AppStore.profil_questions[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>
						<v-col cols="12" class="text-center">
							<div v-if="!AppStore.toggleCGU">
								<v-btn
									@click="passes(submit)"
									:large="AppStore.data.general.button_size === 'large'"
									:x-large="AppStore.data.general.button_size === 'x-large'"
									:x-small="AppStore.data.general.button_size === 'x-small'"
									:small="AppStore.data.general.button_size === 'small'"
									v-if="answersLength === answersLengthes"
									@keypress.enter="navigate" role="link" rounded
									outlined elevation="0"
									:color="Helpers.getColor(AppStore.data.general.primary_color.hexa)"
									class="revert-btn text-button"
									:style="Helpers.getButtonSize(AppStore.data.general.button_size)"
									v-html="AppStore.data.configuration[AppStore.locale].skip_btn">
								</v-btn>
								<v-btn @click="passes(submit)" role="link" rounded v-if="answersLength < answersLengthes"
								       elevation="0" dark
								       :large="AppStore.data.general.button_size === 'large'"
								       :x-large="AppStore.data.general.button_size === 'x-large'"
								       :x-small="AppStore.data.general.button_size === 'x-small'"
								       :small="AppStore.data.general.button_size === 'small'"
								       @keypress.enter="navigate"
								       class="text-button"
								       :color="Helpers.getColor(AppStore.data.general.primary_color.hexa)"
								       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
								       v-html="AppStore.data.configuration[AppStore.locale].send_btn">
								</v-btn>
							</div>
						</v-col>
					</v-card>
					<v-card v-show="AppStore.toggleCGU" elevation="0" class="pa-5 mx-5 mt-5 fill-height" rounded="xl"
					        id="cgu-card" style="overflow-y: auto;">
						<v-icon @click="AppStore.toggleCGU = false" class="float-right" size="30" id="close-cgu">mdi
							mdi-close
						</v-icon>
						<div v-html="AppStore.data.configuration[AppStore.locale].cgu_content"></div>
					</v-card>

					<v-col cols="12" class="text-center">
						<div v-show="AppStore.toggleCGU">
							<v-btn @click="AppStore.toggleCGU = false" role="link" rounded
							       elevation="0" dark
							       :large="AppStore.data.general.button_size === 'large'"
							       :x-large="AppStore.data.general.button_size === 'x-large'"
							       :x-small="AppStore.data.general.button_size === 'x-small'"
							       :small="AppStore.data.general.button_size === 'small'"
							       @keypress.enter="navigate"
							       class="text-button mt-3"
							       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
							       :color="Helpers.getColor(AppStore.data.general.primary_color.hexa)"
							       v-html="AppStore.data.configuration[AppStore.locale].cgu_close_btn">
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</ValidationObserver>

</template>

<script>
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import Helpers from "../../../services/Helpers";
import SliderView from '../../../components/communs/inputViewer/SliderView.vue'
import TextView from '../../../components/communs/inputViewer/TextView.vue'
import TextareaView from '../../../components/communs/inputViewer/TextareaView.vue'
import SelectView from '../../../components/communs/inputViewer/SelectView.vue'
import CheckboxView from '../../../components/communs/inputViewer/CheckboxView.vue'
import RadioView from '../../../components/communs/inputViewer/RadioView.vue'
import SwitchView from '../../../components/communs/inputViewer/SwitchView.vue'
import {EventBus} from "../../../services/Request";
import DividerView from "../../../components/communs/inputViewer/DividerView.vue";
import {ValidationObserver} from 'vee-validate';

export default {
	name: 'questions-view',

	components: {
		DividerView,
		ValidationObserver,
		SliderView,
		TextView,
		TextareaView,
		SelectView,
		CheckboxView,
		RadioView,
		SwitchView
	},

	props: {},

	data: () => ({
		AppStore,
		Helpers,
		EventBus,
		answersLength: Object.keys(AppStore.rating.questions_answers).length
	}),

	created() {
		setTimeout(() => {
			this.calculCguHeight()
		}, 300);

		window.addEventListener("orientationchange", () => {
			this.calculCguHeight()
		}, false);

		window.addEventListener("resize", () => {
			this.calculCguHeight()
		}, false);
	},

	mounted() {
		EventBus.$on('update-question', (data) => {
			if (data.show) {
				this.$refs['qi' + data.id][0].$el.classList.remove('d-none')
			} else {
				// console.log(this.$refs['qi' + data.id])
				this.$refs['qi' + data.id][0].$el.classList.add('d-none')
				delete AppStore.rating.questions_answers[data.id]
				this.$refs['qi' + data.id][0].sliderValue = 0
			}
		});
	},

	computed: {
		answersLengthes() {
			return Object.keys(AppStore.rating.questions_answers).length
		}
	},

	watch: {
		_() {
			return _;
		},
	},

	methods: {
		calculCguHeight() {
			const height = window.innerHeight - document.querySelector('#header').offsetHeight - document.querySelector('#footer').offsetHeight - 170 + 'px'
			document.getElementById("cgu-card").style.height = height;
		},

		submit() {
			if (AppStore.data.configuration.skip_timeline_page) {
				this.$router.push({name: 'tb-1-home'})
			} else {
				this.$router.push({name: 'tb-1-timeline'})
			}

			EventBus.$emit('storeRating', 'noReset')
		},
	}
};
</script>


